
import { defineComponent, ref, watch } from "vue";
import { Modal } from "bootstrap";
import { IRemittance } from "../HistoryPage.vue";
import axios, { AxiosError } from "axios";
import useAlert from "@/composables/Alert";
import { useLoaderStore } from "@/store/LoaderStore";

export type TRemittanceCancelInfo = {
  isCanceled: boolean
}

export default defineComponent({
  name: "ModalCancelRemittance",
  props: {
    toggle: {
      type: Boolean,
      default: false
    },
    remittance: {
      type: Object,
      require: true
    }
  },
  setup(props, { emit }) {
    // Variables
    let auxModal
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const remittance = ref<IRemittance | null>(null)

    // Functions
    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    }

    function hanldeExtractFilenameFromURL(url: string): string {
      const parts = url.split('/');
      return parts[parts.length - 1];
    }

    async function handleCancelRemittance() {
      if(!remittance.value)
        return
      try {
        loaderStore.open()
        const result = await axios.put(`/api/cancelRemittance/${remittance.value.id}`)
        showTimeAlert(`A remessa ${hanldeExtractFilenameFromURL(result.data.data.url_file)} foi cancelada com sucesso!`)
        emit("getRemittanceCancelInfo", <TRemittanceCancelInfo>{ isCanceled: true });
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
      }
    }

    // Life Cycles
    watch(() => props.toggle, () => {
      if(!props.remittance) return
      remittance.value = props.remittance as IRemittance;
      openModal("modal-cancel-remittance")
    })

    return {
      remittance,
      hanldeExtractFilenameFromURL,
      handleCancelRemittance
    }
  }
})
