
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, onMounted, watch, onBeforeMount } from 'vue';
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampEmptyListFeedbackV2
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { useFilterMainStore } from '@/store/FilterMainStore';
import { useCompanyStore } from '@/store/CompanyStore';
import { useStoreStore } from '@/store/StoreStore';
import { useRemittanceStore } from '@/store/RemittanceStore';
import ModalCancelRemittance, { TRemittanceCancelInfo } from "./Components/ModalCancelRemittance.vue";
import moment from 'moment';
import { campHandleCPF } from '@/composables/DataValidation';

type TStatusOptions = "pendente aprovacao" | "aprovado" | "pendente pagamento" | "finalizado" | "cancelada" | "pago" | null

export interface IRemittance {
  id: number;
  date: string;
  url_file: string;
  status: TStatusOptions;
  total_points: number;
  user_name: string;
  value: number;
}

interface IRemittanceFilesResponse {
  data: {
    data: IRemittance[]
    error: boolean,
    message: string,
    errorCode: string
  }
}

export default defineComponent({
  name: "RemittanceHistoryList",
  components: {
    CampHeader,
    CampTable,
    CampTableTd,
    CampTableTh,
    ModalCancelRemittance,
    CampEmptyListFeedbackV2
  },
  setup() {
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const filterMainStore = useFilterMainStore()
    // Company
    const companyStore = useCompanyStore()
    // Store
    const storeStore = useStoreStore()
    // Remittance
    const isLoading = ref(false)
    const remittanceStore = useRemittanceStore()
    const remittance = ref<IRemittance | null>(null)
    const remittanceFilesList = ref<IRemittance[] | null>(null)
    const remittanceFilesListRender = ref<IRemittance[] | null>(null)
    const keywordSearch = ref("")
    const toggleModalHistoryDetails = ref(false)
    const togglePayment = ref(<any>[])

    // Functions
    /* Fetch */
    async function getRemittanceFilesList() {
      if(!companyStore.getId)
        return true
      keywordSearch.value = ""
      try {
        isLoading.value = true
        let url = `/api/getRemittanceFiles/${companyStore.getId}`
        let params = "?"
        if(storeStore.getId) {
          params += `id_store=${storeStore.getId}&`
        }

        if(remittanceStore.getStartDate && remittanceStore.getEndDate) {
          params += `start_date=${moment(remittanceStore.getStartDate).format('DD-MM-YYYY')}&end_date=${moment(remittanceStore.getEndDate).format('DD-MM-YYYY')}&`
        }
        
        if(params !== "?") {
          url += params.slice(0, -1)
        }
        const response: IRemittanceFilesResponse = await axios.get(url)
        const { data } = response.data
        remittanceFilesList.value = data
        remittanceFilesListRender.value = data.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf())
        togglePayment.value = remittanceFilesListRender.value.map(e => e.status === 'pago')
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
        isLoading.value = false
      }
      return false
    }

    function updateRemittanceFilesList(info: TRemittanceCancelInfo) {
      if(info.isCanceled)
        getRemittanceFilesList()
    }

    function handleFilterObjectsByKeyword(): boolean {
      if(!remittanceFilesList.value)
        return true
      remittanceFilesListRender.value = remittanceFilesList.value
      .filter((item) => {
        for (const key in item) {
          if (key !== 'checked') {
            const propValue = item[key];
            if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
              return true;
            }
            if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
              return true;
            }
          }
        }
        return false;
      })
      .sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf());
      return false
    }

    /* Helpers */
    function handleFileName(url: string): string {
      const parts = url.split("/");
      return parts[parts.length - 1];
    }

    function handleStatusColor(status: TStatusOptions) {
      switch(status) {
        case 'pendente aprovacao':
          return 'var(--cor_indicadores_3_opct)';
        case 'aprovado':
          return 'var(--cor_indicadores_1_opct)';
        case 'pendente pagamento':
          return 'var(--cor_indicadores_3)';
        // case 'finalizado':
        //   return 'var(--cor_indicadores_1)';
        case 'cancelada':
          return 'var(--cor_indicadores_4)';
        case 'pago':
          return 'var(--cor_indicadores_1)';
        default:
          return 'var(--cor_texto_1)'
      }
    }

    /* Local Storage */
    function getDataLocalStorage() {
      const resetCompany = ():void => {
        companyStore.setId(null)
        companyStore.setFantasyName("")
      }
      try {
        const cs = localStorage.getItem("cs") ? JSON.parse(localStorage.getItem("cs")!) : null
        if(cs && cs.i) {
          companyStore.setId(cs.i)
          companyStore.setFantasyName(cs.n)
        } else {
          resetCompany()
        }
      } catch (error) {
        resetCompany()
      }

      const resetStore = (): void => {
        storeStore.setId(null)
        storeStore.setFantasyName("")
      }
      try {
        const ss = localStorage.getItem("ss") ? JSON.parse(localStorage.getItem("ss")!) : null
        if(ss && ss.i) {
          storeStore.setId(ss.i)
          storeStore.setFantasyName(ss.n)
        } else {
          resetStore()
        }
      } catch (error) {
        resetStore()
      }

      try {
        const dr = localStorage.getItem("dr")
        if(dr) {
          remittanceStore.setDateRange(dr.split(","))
        } else {
          remittanceStore.setDateRange("")
        }
      } catch (error) {
        remittanceStore.setDateRange("")
      }
    }

    function handleRemittanceValue(value) {
      if(value % 1 !== 0) {
        return value.toString().split('.')[0] + ',' + value.toString().split('.')[1]
      } else {
        return value.toFixed(2).toString().split('.')[0] + ',' + value.toFixed(2).toString().split('.')[1]
      }
    }
    
    /* Handle Modals */
    function handleInitFilterModal() {
      if(!companyStore.getId) {
        filterMainStore.hideModal()
        filterMainStore.showModal()
      }
    }

    function handleModalCancelRemittance(el: IRemittance) {
      if(el) {
        remittance.value = el
        toggleModalHistoryDetails.value = !toggleModalHistoryDetails.value
      }
    }

    async function paymentRemittance(el: IRemittance, action: boolean) {
      if(el) {
        try {
          loaderStore.open()
          let url = action ? `/api/paymentRemittance` : `/api/cancelPaymentRemittance`
          let body = {
            'remittance_id': el.id
          }
          await axios.post(url, body)
          await getRemittanceFilesList()
      } catch (error) {
          if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
         } else {
            showTimeAlert("Algo deu errado!", "error")
          }
      } finally {
          loaderStore.close()
          // isLoading.value = false
      }
      }
    }

    // Life Cycles
    onBeforeMount(() => getDataLocalStorage())

    onMounted(() => {
      getRemittanceFilesList()
      handleInitFilterModal()
    })

    watch(
      () => { companyStore.getId, storeStore.getId }, 
      () => isLoading.value = true,
      { deep: true }
    )
    watch(
      () => { companyStore.company, storeStore.store, remittanceStore.getStartDate, remittanceStore.getEndDate }, 
      () => getRemittanceFilesList(),
      { deep: true }
    )

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

    return {
      isLoading,
      keywordSearch,
      companyStore,
      remittance,
      remittanceFilesList,
      remittanceFilesListRender,
      handleFilterObjectsByKeyword,
      handleFileName,
      handleStatusColor,
      toggleModalHistoryDetails,
      handleModalCancelRemittance,
      updateRemittanceFilesList,
      moment,
      remittanceStore,
      campHandleCPF,
      paymentRemittance,
      togglePayment,
      handleRemittanceValue,
    }
  }
})
